import { createStore } from 'vuex'

export default createStore({
  state: {
    url: '',
    loads: {},
    worksheet: 'Страницы',
    worksheetMode: 0, // 0 - Показы, 1 - Клики
    worksheets: null,
    exceptionlists: ['Страницы'],
    project: null,
    projects: [],
    allusers: [],
    Role: null
  },
  mutations: {
    setWorksheet(state, wsname) {
      state.worksheet = wsname
    },
    setWorksheetMode(state, mode) {
      state.worksheetMode = mode
    },
    setAllUsers(state, users) {
      state.allusers = users
    },
    setWorksheets(state, worksheets) {
      state.worksheets = worksheets
    },
    setUrl(state, url) {
      state.url = url
    },
    toggleLoad(state, name) {
      if (state.loads[name])
        delete state.loads[name]
      else
        state.loads[name] = true
    },
    setRole(state, Role) {
      state.Role = Role
    },
    setProject(state, value) {
      state.project = value
    },
    setProjects(state, value) {
      state.projects = value
    },
  },
  actions: {
    reset({ commit }) {
      commit('setRole', false)
    },
    async saveExceptions({ state, commit, dispatch, getters }, newException) {
      commit('toggleLoad', 'saveExceptions')
      let exceptions = getters.exceptions.map(el=>el.trim()).filter(el=>el)
      if (!exceptions || !Array.isArray(exceptions)) {
        alert('Ошибка: exceptions is ' + (exceptions? typeof(exceptions): exceptions))
        commit('toggleLoad', 'saveExceptions')
        return false
      }
      if (newException && (newException.trim()))
        exceptions.push(newException.trim())
      let ok = await fetch(state.url + 'exceptions', {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ project: state.project.name, wsname: state.worksheet, exceptions })
      }).then(async res => {
        if (!res.ok) throw (await res.text())
        dispatch('updateProjects')
        return true
      }).catch(err => { alert(err); console.error(err); return false })
      commit('toggleLoad', 'saveExceptions')
      return ok
    },
    async checkAuth({ state, commit }) {
      commit('toggleLoad', 'checkAuth')
      await fetch(state.url + 'auth', {credentials: 'include',})
        .then(res => {
          if (!res.ok) return commit('setRole', false)
          return res.text()
        }).then(role => {
          commit('setRole', role || false)
        }).catch(err => {
          console.error(err)
          commit('setRole', false)
        })
      commit('toggleLoad', 'checkAuth')
    },
    async updateAllUsers({ state, commit }) {
      commit('toggleLoad', 'updateAllUsers')
      await fetch(state.url + 'users', {credentials: 'include',})
        .then(async res => {
          if (!res.ok) throw (await res.text())
          return res.json()
        })
        .then(list => {
          commit('setAllUsers', list)
        })
        .catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'updateAllUsers')
    },
    async updateProjects({ state, commit }) {
      commit('toggleLoad', 'updateProjects')
      await fetch(state.url + 'projects', {credentials: 'include',})
        .then(async res => {
          if (!res.ok) throw (await res.text())
          return res.json()
        })
        .then(list => {
          commit('setProjects', list)
          if (state.project) {
            let found = list.find(el => el.name == state.project.name)
            commit('setProject', found || null)
          }
        })
        .catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'updateProjects')
    },
    async sendFiles({ state, commit, dispatch }, data) {
      commit('toggleLoad', 'sendFiles')
      await fetch(state.url + 'data', {
        credentials: 'include',
        method: 'POST',
        body: data,
      })
        .then(async res => {
          if (!res.ok) throw (await res.text())
          await dispatch('getWorksheets')
        })
        .catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'sendFiles')
    },
    async getWorksheets({ state, commit }) {
      commit('toggleLoad', 'getWorksheets')
      await fetch(state.url + 'getdata', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ project: state.project.name, wsname: state.worksheet })
      }).then(async res => {
        if (!res.ok) throw (await res.text())
        return res.json()
      }).then(worksheets => {
        commit('setWorksheets', worksheets.sort((f1, f2) => f2.date - f1.date))
      }).catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'getWorksheets')
    },
    async deleteWorksheets({ state, commit, dispatch }, index) {
      commit('toggleLoad', 'deleteWorksheets')
      await fetch(state.url + 'data', {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ project: state.project.name, date: state.worksheets[index].date })
      }).then(async res => {
        if (!res.ok) throw (await res.text())
        return await dispatch('getWorksheets')
      }).catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'deleteWorksheets')
    },
  },
  getters: {
    loading(state) {
      return Object.keys(state.loads).length > 0
    },
    exceptions(state) {
      if (!state.project) {
        return null 
      }
      if (!state.exceptionlists.includes(state.worksheet))
        return []
      return state.project.exceptions
    }
  }
})
